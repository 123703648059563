<template>
  <b-sidebar
    id="bundle-details-sidebar"
    refs="bundleDetailsSidebar"
    bg-variant="white"
    shadow
    backdrop
    right
    width="100%"
    :title="packType"
    no-close-on-backdrop
    aria-modal="true"
  >
  <template #footer="{ hide }">
      <div class="d-flex justify-content-end border-top p-1">
        <b-button variant="danger" size="sm" @click="onCancel(hide)">
          Cancel
        </b-button>
      </div>
    </template>
    <template #default>
      <div class="container-fluid">
        <b-card no-body>
          <b-row>
            <b-col>
              <b-table
                :fields="fields"
                :items="bundleItems"
                bordered
                class="table-sm"
              />
            </b-col>
          </b-row>
        </b-card>
      </div>
    </template>
    <template #header="{ hide }">
      <div class="d-flex justify-content-between p-1 w-100">
        <div>
          <strong>{{ packType }} Details</strong>
        </div>
        <div><b-btn-close @click="hide" /></div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
export default {
  props: {
    bundleItems: {
      type: Array,
      required: true,
    },
    packType: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    fields: [
      { key: 'title' },
      { key: 'upc' },
      { key: 'asin' },
      { key: 'status' },
      { key: 'count' },
      { key: 'Notes' },
    ],
  }),
  methods: {
    onCancel(hide) {
      hide()
    },
  },
}
</script>

<style></style>
