import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {
  // find bundle
  async findBundleByProductId(params) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.ship.BundleController.findBundleByProductId(params))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async findByQuery(params, pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.ship.BundleController.findByQuery(params, pageable))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async create(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.ship.BundleController.create(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async deleteById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.ship.BundleController.deleteById(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
}
