<template>
    <b-container fluid>
      <div class="row">
        <div class="col-md-12">
          <b-breadcrumb class="breadcrumb-chevron">
      <b-breadcrumb-item :to="{ name: 'dashboard-summary' }">
        Home
      </b-breadcrumb-item>
      <b-breadcrumb-item>
        Ship
      </b-breadcrumb-item>
      <b-breadcrumb-item :to="{ name: 'uploaded-batches' }">
        Shipped Batches
      </b-breadcrumb-item>
      <b-breadcrumb-item active>
        Bundles
      </b-breadcrumb-item>
    </b-breadcrumb>
        </div>
      </div>

      <bundle-details :bundle-items="bundleItems" :pack-type="packType" />
      <b-card>
        <b-row>
          <b-col cols="3" class="text-left">
            <b-form-input
              id="filter-input"
              v-model="filters.query"
              class="d-inline-block mr-1"
              type="search"
              placeholder="Search..."
            />
          </b-col>
          <b-col cols="3" class="text-left mt-1">
            <h3 class="text-success">
              Batch Name: {{ batchName }}
            </h3>
          </b-col>
          <b-col class="text-right">
            <b-button-group
              size="lg"
            >
              <b-button
                v-b-tooltip.d800.hover.v-secondary="'Refresh'"
                class="btn-icon"
                variant="outline-success"
                @click="searchByFilters"
              >
                <feather-icon icon="RefreshCwIcon" />
              </b-button>
            </b-button-group>
          </b-col>

        <b-col lg="12" class="mt-1">
          <b-table
            ref="refShipmentBundlesListTable"
            :busy.sync="grid.isBusy"
            :fields="grid.fields"
            :items="grid.tableData"
            :sort-by.sync="grid.sortBy"
            :sort-desc.sync="grid.isSortDirDesc"
            :filter="filters.query"
            :filter-included-fields="filters.filterOn"
            bordered
            class="table-vcenter"
            empty-text="No matching records found"
            hover
            small
            primary-key="id"
            responsive
            show-empty
            striped
            :per-page.sync="paged.pageSize"
            :current-page="paged.pageNumber"
            @filtered="onFiltered"
          >
            <div slot="table-busy" class="text-center m-1 pt-3 pb-0">
              <div class="spinner-border" role="status" />
              <p class="pt-2">
                <strong>Working on it...</strong><br><small>This shouldn't take long</small>
              </p>
            </div>
             <template #cell(title)="data">
                <b-link
                  v-b-modal.modal-info
                  variant="link"
                  class="text-left m-0"
                  @click="bundleItem(data.item, $event.target)"
                >
                  {{ data.item.title }}
                </b-link>
              </template>
            <!-- Status -->
            <template #cell(status)="data">
              <span
                :class="`badge-light-${resolveShipDateVariant(data.item.ShipmentDate)}`"
                class="text-capitalize badge"
              >
                {{ data.item.status }}
              </span>
            </template>

            <template #cell(actions)="data">
              <div class="text-nowrap">
                <b-button
                  v-b-tooltip.d800.hover.left.v-secondary="'Delete Bundle'"
                  variant="flat-danger"
                  class="btn-icon list-preps"
                  @click="deleteItem(data.item.id)"
                >
                  <feather-icon icon="TrashIcon" size="18" />
                </b-button>
              </div>
            </template>
          </b-table>
          <robustshell-grid-pagination
            :options.sync="pageable"
            :changed="onChangeData"
          />
        </b-col>
                </b-row>
      </b-card>
    </b-container>
</template>

<script>
import shipService from '@/http/requests/ship/shipService'
import bundleService from '@/http/requests/ship/bundleService'
import { VBToggle, BBreadcrumb } from 'bootstrap-vue'
import { currencyFormatter } from '@core/utils/filter'
import moment from 'moment'
import Pageable from '@robustshell/components/Grid/pageable'
import BundleDetails from './BundleDetails.vue'

export default {
  components: {
    'bundle-details': BundleDetails,
  },
  directives: {
    'b-toggle': VBToggle,
    BBreadcrumb,
  },
  data: () => ({
    filters: {
      query: '',
      filterOn: [],
    },
    grid: {
      isBusy: false,
      selectedItem: {
        id: 0,
      },
      selectedItems: [],
      fields: [
        { key: 'title', sortable: true },
        { key: 'asin', sortable: true },
        {
          key: 'quantity', label: 'QTY', class: 'text-right', sortable: true,
        },
        {
          key: 'receivedCount', label: 'Received', class: 'text-right', sortable: true,
        },
        {
          key: 'shippedCount', label: 'Shipped', class: 'text-right', sortable: true,
        },
        { key: 'status', sortable: true },
        { key: 'packType', sortable: true },
        {
          key: 'cost',
          formatter: currencyFormatter,
          sortable: true,
        },
        {
          key: 'salePrice',
          label: 'Price',
          formatter: currencyFormatter,
          sortable: true,
        },
        { key: 'itemAction', sortable: true },
        { key: 'actions', class: 'smallCell' },
      ],
      tableData: [],
      sortBy: 'id',
      isSortDirDesc: true,
    },
    bundleItems: [],
    packType: '',
    batchId: '',
    batchName: '',
    pageable: new Pageable({ pageSize: 50 }),
    paged: {
      pageSize: 50,
      pageNumber: 1,
    },
  }),
  mounted() {
    this.paged = Object.assign(
      this.paged,
      this.pageable.toCreatePageblePayload(),
    )
  },
  created() {
    this.batchId = this.$route.query.batchId
    this.batchName = this.$route.query.batchName
    this.getShipmentBundlesByQuery()
  },
  methods: {
    resolveShipDateVariant(date) {
      const days = Math.ceil(
        moment.duration(moment().diff(moment(date))).asDays(),
      )
      if (days < 14) return 'success'
      if (days >= 21) return 'danger'
      return 'warning'
    },
    refreshTable() {
      this.$refs.refShipmentBundlesTable.refresh()
    },
    searchByFilters() {
      this.getShipmentBundlesByQuery()
    },
    setPagination(context) {
      this.pageable.setContext(context)
    },
    getShipmentBundlesByQuery() {
      this.grid.isBusy = true
      this.grid.tableData.splice(0, this.grid.tableData.length)
      const params = 'batchId='.concat(this.batchId)
      bundleService.findByQuery(
        params,
        this.pageable.toCreateQueryString(),
      )
        .then(result => {
          const data = result.data.content
          this.grid.tableData.push(...data)
          this.setPagination(result.data)
          this.grid.isBusy = false
        })
    },
    deleteItem(id) {
      this.$swal({
        title: 'Are you sure you want to delete this Bundle record? Please not that items you seleced in this bundle will be acted as individual items',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          shipService.deleteBundleById(id).then(() => {
            const index = this.grid.tableData.findIndex(u => u.id === id)
            this.grid.tableData.splice(index, 1)
            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              text: 'Bundle record has been deleted.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
        }
      })
    },
    bundleItem(item) {
      shipService.fetchItemsByBundleId(item.id)
        .then(result => {
          this.bundleItems = result.data
          this.packType = item.packType.toUpperCase().split('_').join(' ').replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
          this.$root.$emit('bv::toggle::collapse', 'bundle-details-sidebar')
        })
    },
    onChangeData() {
      this.getShipmentBundlesByQuery()
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    editSelectedItem(context) {
      this.grid.selectedItem = context
      this.$root.$emit('bv::toggle::collapse', 'item-edit-sidebar')
    },
  },
}
</script>

<style lang="scss">
.xsmallCell {
  width: 80px;
}
.smallCell {
  width: 100px;
}
.mediumCell {
  width: 130px;
}
</style>
